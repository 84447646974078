import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import copy from 'copy-to-clipboard'

import { deleteContactRequest } from '@services/contactrequestservice'

import {
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core'

import Copy from '@objects/copy'
import ContextMenu from '@objects/contextmenu/'

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(10),
    width: '100%',
    '&.comment': {
      borderRadius: 0,
    },
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(3),
  },
  cardactions: {
    padding: theme.spacing(3),
    justifyContent: 'flex-end',
  },
  info: {
    fontSize: '14px',
    color: theme.palette.primary.contrastText,
  },
}))

function ContactRequestCard({
  className,
  onActionClick,
  id,
  text,
  name,
  email,
  timestamp,
}) {
  const classes = useStyles()

  const ContextMenuCommonProps = {
    id: id,
    userName: name,
    userEmail: email,
    mailto: `subject=&body=${text
      ?.replace(/&nbsp;/gi, ' ')
      .replace(/<\/?[^>]+>/gi, '')}`,
    onCopy: onCopyClick,
    onDelete: onDeleteClick,
  }

  function onCopyClick() {
    copy(text, { format: 'text/html' })
  }

  function onDeleteClick() {
    deleteContactRequest(id).then((res) => {
      if (onActionClick) onActionClick()
    })
  }

  return (
    <Box position="relative" className={className}>
      <Card elevation={3} className={classNames(classes.card)}>
        <Box className={classNames(classes.toolbar)}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column">
              <Typography className={classes.info}>{name}</Typography>
              <Typography className={classes.info}>{email}</Typography>
            </Box>
            <Typography className={classes.info}>
              {moment(timestamp).locale('de').format('DD.MM.YYYY HH:mm')}
            </Typography>
          </Box>
        </Box>
        <CardContent>
          <Copy html={text} />
        </CardContent>
        <CardActions className={classes.cardactions}>
          <ContextMenu {...ContextMenuCommonProps} />
        </CardActions>
      </Card>
    </Box>
  )
}

ContactRequestCard.propTypes = {
  className: PropTypes.string,
  onActionClick: PropTypes.func,
  id: PropTypes.number,
  name: PropTypes.string,
  email: PropTypes.string,
  text: PropTypes.string,
  timestamp: PropTypes.string,
}

export default ContactRequestCard
