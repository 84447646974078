import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { getContactRequests } from '@services/contactrequestservice'

import LoadingSkeleton from '@objects/loadingSkeleton'
import WarningNote from '@objects/warningnote'
import ContactRequestCard from '@objects/cards/contactrequestcard'

export const frontmatter = {
  title: 'Kontaktanfragen',
}

function ContactRequestPage() {
  const intl = useIntl()
  const [cards, setCards] = useState([])

  useEffect(() => {
    getContactRequests().then((data) => {
      setCards(data)
    })
  }, [])

  function onActionClick() {
    getContactRequests().then((data) => {
      setCards(data)
    })
  }

  function renderCards() {
    if (!cards.length) return

    return cards.map((card) => (
      <ContactRequestCard
        key={card.id}
        onActionClick={onActionClick}
        id={card.id}
        timestamp={card.timestamp}
        name={card.name}
        email={card.email}
        text={card.text}
      />
    ))
  }
  return (
    <>
      <WarningNote
        text={intl.formatMessage({
          id: 'contactrequestpage.introtext',
        })}
      />
      <LoadingSkeleton loading={!!!cards.length} type="table">
        {renderCards()}
      </LoadingSkeleton>
    </>
  )
}

ContactRequestPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default ContactRequestPage
