import apiClient from './basicapi'

function getContactRequests() {
  return apiClient.get('contactadmin').then((res) => res.data)
}

function deleteContactRequest(id) {
  return apiClient.delete(`contactadmin/${id}`)
}

export { getContactRequests, deleteContactRequest }
